import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home, StrategyPage } from './Pages';
import InteriorDesign from 'src/Pages/InteriorDesign';
import { PATH } from 'src/constants/paths';
import AudioVisualService from 'src/Pages/AudioVisualService';
import BuildServices from 'src/Pages/BuildServices';
import AboutUs from 'src/Pages/AboutUs';
import BusinessRates from 'src/Pages/BusinessRates';
import PropertyDisposal from 'src/Pages/PropertyDisposal';
import BuildingConsultancy from 'src/Pages/BuildingConsultancy';
import TenantRepresentation from './Pages/TenantRepresentation';
import FurnitureServices from './Pages/FurnitureServices';
import Teams from './Pages/Teams';
import CaseStudies from './Pages/CaseStudies';
import CovidResponse from './Pages/CovidResponse';
import HumanExperience from './Pages/HumanExperience';
import BusinessExcellence from './Pages/BusinessExcellence';
import FutureProofing from './Pages/FutureProofing';
import More from './Pages/More';
import Compass from './Pages/Compass';
// import PropertiesSearch from './Pages/PropertiesSearch';
// import PropertiesSearchResult from './Pages/PropertySearchResult';
import Leasehold from './Pages/Leasehold';
import PropertyCheckout from './Pages/PropertyCheckout';
// import Confirmation from './Pages/Confirmation/Confirmation';
import Privacy from './Pages/Privacy/index';
import Policy from './Pages/Policy/index';
import Prosek from './Pages/CasestudiesItemone/Prosek';
import Lovecraft from './Pages/CasestudiesItemone/Lovecraft';
import Eb7 from './Pages/CasestudiesItemone/Eb7';
import Solagroup from './Pages/CasestudiesItemone/Solagroup';
import Hive from './Pages/CasestudiesItemone/Hive';

function Routers() {
  return (
    <Router>
      <Switch>
        <Route exact path={PATH.HOME} component={Home} />
        <Route exact path={PATH.APPROACH} component={StrategyPage} />
        <Route exact path={PATH.INTERIOR_DESIGN} component={InteriorDesign} />
        <Route
          exact
          path={PATH.AUDIO_VISUAL_SERVICE}
          component={AudioVisualService}
        />
        <Route exact path={PATH.BUILD_SERVICE} component={BuildServices} />
        <Route exact path={PATH.ABOUT_US} component={AboutUs} />
        <Route exact path={PATH.BUSINESS_RATES} component={BusinessRates} />
        <Route
          exact
          path={PATH.PROPERTY_DISPOSAL}
          component={PropertyDisposal}
        />
        <Route
          exact
          path={PATH.PROJECT_MANAGMENT}
          component={BuildingConsultancy}
        />
        <Route
          exact
          path={PATH.TENANT_REPRESENTATION}
          component={TenantRepresentation}
        />
        <Route
          exact
          path={PATH.FURNITURE_SERVICES}
          component={FurnitureServices}
        />
        <Route exact path={PATH.TEAM} component={Teams} />
        <Route exact path={PATH.CASE_STUDIES} component={CaseStudies} />
        <Route exact path={PATH.COVID_RESPONSE} component={CovidResponse} />
        <Route exact path={PATH.HUMAN_EXPERIENCE} component={HumanExperience} />
        <Route
          exact
          path={PATH.BUSINESS_EXCELLENCE}
          component={BusinessExcellence}
        />
        <Route exact path={PATH.FUTURE_PROOFING} component={FutureProofing} />
        <Route exact path={PATH.MORE} component={More} />
        <Route path={PATH.COMPASS} component={Compass} />
        {/* <Route exact  path={PATH.PROPERTYSEARCH} component={PropertiesSearch} /> */}
        {/* <Route
          exact
          path={PATH.PROPERTYSEARCHRESULT}
          component={PropertiesSearchResult}
        /> */}
        {/* <Route exact path={PATH.LEASEHOLD} component={Leasehold} /> */}
        {/* <Route
          exact
          path={PATH.PROPERTYCHECKOUT}
          component={PropertyCheckout}
        /> */}
        <Route exact path={PATH.PRIVACY} component={Privacy} />
        <Route exact path={PATH.POLICY} component={Policy} />
        <Route exact path={PATH.CASE_STUDIES_FIRST} component={Prosek} />
        <Route exact path={PATH.CASE_STUDIES_SECOND} component={Lovecraft} />
        <Route exact path={PATH.CASE_STUDIES_THIRD} component={Eb7} />
        <Route exact path={PATH.CASE_STUDIES_FOURTH} component={Solagroup} />
        <Route exact path={PATH.CASE_STUDIES_FIFTH} component={Hive} />
        {/* <Redirect  to="/compas" /> */}

        {/* <Route path='*' component={Home} />   */}
      </Switch>
    </Router>
  );
}
export default Routers;
